import eventsTrackingSerpLoadMore from 'shared/experiments/browser/SerpLoadMore/eventsTracking'
import scrollToTopMobile from 'shared/experiments/browser/SerpLoadMore/scrollToTopMobile'
import serpNowPush from 'shared/experiments/browser/serpNowPush/serpNowPush'
import { subscribe } from 'shared/store'
import { SEARCH_RESULTS_HITS_UPDATED } from 'shared/store/ducks/events'
import floatingLabel from 'views/assets/scripts/floatingLabel'
import odsIndikationUrl from 'views/assets/scripts/odsIndikationUrl'
import registerSliderStretcher from 'views/assets/scripts/registerSliderStretcher'
import runScripts from 'views/assets/scripts/runScripts'
import searchResultsHandlers from 'views/assets/scripts/searchResultsHandlers'
import { initSlider as slider } from 'views/assets/scripts/slider'
import initClientScriptsXsellSellerModal from 'views/components/organisms/XsellSellerModal/initClientScripts'
import mountAlgoliaSearchComponent from 'views/containers/browser/AlgoliaInstantSearchContainer/mountAlgoliaSearchComponent'
import mountNowFaqModalContainer from 'views/containers/browser/NowFaqModalContainer/mountNowFaqModalContainer'
import mountNowShippingModalContainer from 'views/containers/browser/NowShippingModalContainer/mountNowShippingModalContainer'
import mountSellerModalContainer from 'views/containers/browser/SellerModalContainer/mountSellerModalContainer'
import mountVolumePricingModalContainer from 'views/containers/browser/VolumePricingModalContainer/mountVolumePricingModalContainer'

const handleSellerModalContainer = () => {
  subscribe.after(SEARCH_RESULTS_HITS_UPDATED, () => {
    /**
     * we call it here again so we can reattach click handler on seller links after
     * they are dynamically loaded on click on pagination links.
     */
    mountSellerModalContainer()
    odsIndikationUrl()
  })
}

runScripts(
  mountAlgoliaSearchComponent,
  slider,
  searchResultsHandlers,
  floatingLabel,
  mountNowShippingModalContainer,
  serpNowPush,
  eventsTrackingSerpLoadMore,
  scrollToTopMobile,
  odsIndikationUrl,
  mountNowFaqModalContainer,
  handleSellerModalContainer,
  initClientScriptsXsellSellerModal,
  registerSliderStretcher,
  mountVolumePricingModalContainer
)
